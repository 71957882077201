<template>
  <div class="wrapper">
    <div class="res_wrapper">
      <div class="icon_box">
        <img class="icon_img" src="../../../assets/images/wangshangdai/icon_success.png" alt="">
      </div>
      <div class="text_box">提交成功</div>
      <div class="text">云卡会在7个工作日内授信审核</div>
      <div class="text">审核通过后即可通过云卡小程序使用e付通</div>
      <div class="btn-box">
        <div class="btn">
          <a style="color:white" href="alipays://platformapi/startapp?appId=2021002145687024&page=/pages/ucenter/login/index/index/">立即访问云卡支付宝小程序</a>
        </div>
      </div>

    </div>
    <div class="codeBox"><img class="codeImg" src="../../../assets/images/wangshangdai/qrcode.png" alt=""></div>
    <div class="text_box">云卡支付宝小程序</div>
    <div class="text">您还可以截图保存云卡小程序二维码稍后访问</div>
    <div class="text">或者在支付宝中搜索 <span style="color:#FD8F2A">“云卡车队管理小程序”</span></div>
    <div class="footer">
      <img class="footer-img" src="../../../assets/images/wangshangdai/footer.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "result",
    methods: {

    }
  }
</script>

<style scoped lang="less">
  .wrapper {
    width: 100%;
    background-color: #F7F7F7;
    padding-top: 1.25rem;

    .footer {
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .footer-img {
        width: 25.17rem;
        height: auto
      }
    }

    .text_box {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.67rem;
      font-weight: bold;
      margin: 0.5rem;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.17rem;
      color: #757575;
    }

    .res_wrapper {
      width: 28.75rem;
      height: 30rem;
      background: #FFFFFF;
      box-shadow: 0 0.5rem 0.83rem 0 rgba(150, 150, 150, 0.10);
      border-radius: 0.67rem;
      margin: 0 1.25rem 1.25rem 1.25rem;

      .icon_box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top:1.92rem;

        .icon_img {
          width: 10rem;
          height: 10rem
        }
      }

      .btn-box {
        margin-top: 3.17rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn {
        font-size: 1.42rem;
        color: #ffffff;
        background-color: #FF7900;
        width: 24.83rem;
        height: 3.75rem;
        line-height: 3.75rem;
        text-align: center;
        border-radius: 1.88rem;
      }

    }
    .codeBox {
      margin: 2.67rem 9.67rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 11.67rem;
      height: 11.67rem;
      border-radius: 0.67rem;
      background-color: white;
      padding:1.08rem 0.83rem;
      .codeImg {
        width: 10rem;
        height: 9.54rem;
      }
    }

  }
</style>
